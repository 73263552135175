import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope, FaTruck, FaFacebookF, FaYoutube, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const TopHeader: React.FC = () => {
  return (
    <div
      className="d-none d-xl-block" // Hide on screens smaller than 1200px, show on screens 1200px and above
      style={{ backgroundColor: '#061a2b', color: '#ffffff', fontSize: '12px' }}
    >
      <Container>
        <Row className="align-items-center py-1">
          {/* Left side - Contact info */}
          <Col xl={4} sm={12} className="d-flex justify-content-start align-items-center flex-wrap">
            <div className="d-flex align-items-center me-3">
              <FaPhoneAlt style={{ marginRight: '8px' }} />
              <span>800.754.6920</span>
            </div>
            <div className="d-flex align-items-center me-3">
              <FaEnvelope style={{ marginRight: '8px' }} />
              <span>customerservice@sawblade.com</span>
            </div>
          </Col>

          {/* Center - Free Ground Shipping */}
          <Col xl={4} sm={12} className="d-flex justify-content-center align-items-center mt-xl-0 mt-2">
            <FaTruck style={{ marginRight: '8px' }} />
            <span>Free Ground Shipping on Orders over $150</span>
          </Col>

          {/* Right side - Social media icons */}
          <Col xl={4} sm={12} className="d-flex justify-content-xl-end justify-content-start align-items-center mt-xl-0 mt-2">
            <FaFacebookF style={{ marginLeft: '15px', cursor: 'pointer' }} />
            <FaYoutube style={{ marginLeft: '15px', cursor: 'pointer' }} />
            <FaTwitter style={{ marginLeft: '15px', cursor: 'pointer' }} />
            <FaInstagram style={{ marginLeft: '15px', cursor: 'pointer' }} />
            <FaLinkedin style={{ marginLeft: '15px', cursor: 'pointer' }} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopHeader;
