import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";
import SpeedFeedRecommendations from "./SpeedFeedRecommendations";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { formatNumber } from "../utils/formatter";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateSpeedFeed } from "../redux/features/speedFeedManagerSlice";
import ToastDisplay from "./ToastDisplay";

const SpeedAndFeedOutput = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const { speedFeedData } = location.state || {};

  const { calculateResult } = useSelector(
    (state: RootState) => state.speedFeedManager
  );

  const { cutQuote, resultArr } = calculateResult;
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [printLoading, setPrintLoading] = useState<boolean>(false);

  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastVariant, setToastVariant] = useState<"success" | "error">(
    "success"
  );
  const [showToast, setShowToast] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (resultArr.length === 0) {
      navigate("/sawbladedata");
    }
  }, [resultArr.length, navigate]);

  const handleDownloadClick = () => {
    const requestData = { ...speedFeedData, generatePDF: true };
    setLoading(true);
    dispatch(calculateSpeedFeed(requestData))
      .unwrap()
      .then((result: any) => {
        if (result.pdfBlob) {
          const url = window.URL.createObjectURL(
            new Blob([result.pdfBlob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "SpeedAndFeedRecommendation.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          setToastMessage("PDF downloaded successfully!");
          setToastVariant("success");
          setShowToast(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      })
      .catch((error) => {
        setToastMessage(error || "Error generating PDF.");
        setToastVariant("error");
        setShowToast(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrintClick = () => {
    const requestData = { ...speedFeedData, generatePDF: true };
    setPrintLoading(true);
    dispatch(calculateSpeedFeed(requestData))
      .unwrap()
      .then((result: any) => {
        if (result.pdfBlob) {
          const url = window.URL.createObjectURL(
            new Blob([result.pdfBlob], { type: "application/pdf" })
          );
          // Open the PDF in a new tab
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.onload = () => {
              newWindow.print();
            };
          }
        }
      })
      .catch((error) => {
        setToastMessage(error || "Error generating PDF for print.");
        setToastVariant("error");
        setShowToast(true);
      })
      .finally(() => {
        setPrintLoading(false);
      });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleCancel = () => {
    setShowModal(false);
    setEmail("");
  };

  const handleEmailClick = () => {
    setEmailError("");

    if (!email) {
      setEmailError("Enter at least one email address.");
      return;
    }

    const emailArray = email.split(",").map((e) => e.trim());

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailArray.filter((e) => !emailRegex.test(e));

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s) entered: ${invalidEmails.join(", ")}`);
      return;
    }

    const requestData = {
      ...speedFeedData,
      generatePDF: true,
      emailTo: emailArray,
    };

    setEmailLoading(true);

    dispatch(calculateSpeedFeed(requestData))
      .unwrap()
      .then((result: any) => {
        if (result.pdfBlob) {
          setShowModal(false);
          setEmail("");
        }
        setToastMessage("Emails sent successfully!");
        setToastVariant("success");
        setShowToast(true);
      })
      .catch((error) => {
        setShowModal(false);
        setToastMessage(error || "Error sending emails.");
        setToastVariant("error");
        setShowToast(true);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const handleStartOverClick = () => {
    navigate("/sawbladedata");
  };

  return (
    <>
      <div className="bannertop">
        <h1 className="text-center text-white">Speed and Feed Calculator</h1>
        {cutQuote.pricePerCut > 0 && (
          <Row className="banner-inner">
            <Col lg={8} md={8} className="bg-white ps-1 py-1">
              <Card className="border-0">
                <Card.Header className="hms-card fs-5">
                  Metal Cutting Estimate
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} md={6} sm={12} className="lh-1 small">
                      <a href="https://www.houstonmetalsawing.com">
                        <Image
                          src={require("../assets/images/HoustonMetalSawing-logo.png")}
                          className="my-3 me-3 float-start"
                          alt="Houston Logo"
                        />
                      </a>
                      <p>
                        Did you know that we now offer Metal Cutting? We cut
                        beams, bars, structural shapes and all of your metal
                        cutting needs.
                      </p>
                      <div className="mt-1 shapebar">&nbsp;</div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="small">
                      <strong className="fs-6">
                        ${formatNumber(cutQuote.pricePerCut)} per cut
                      </strong>{" "}
                      <span className="small">
                        (+ handling)<sup>*</sup>
                      </span>
                      <div className="pt-3">
                        <a
                          href="tel:7136909292"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          <FontAwesomeIcon
                            icon={fas.faPhoneVolume}
                            className="text-sbc-primary fa-lg"
                            style={{
                              verticalAlign: "-0.05em",
                              color: "#007bff",
                            }}
                            aria-hidden="true"
                          />{" "}
                          713-690-9292
                        </a>{" "}
                        for a detailed quote.
                      </div>
                      <div className="small p-2">
                        * Estimate does not include handling charges as these
                        vary based on a few factors (stock weight, length, etc)
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={4} className="bg-light d-md-block d-none pt-3 ps-0">
              <div className="video-container">
                <iframe
                  src="https://player.vimeo.com/video/747437902?h=9b803602d1&amp;color=ef0022&amp;title=0&amp;byline=0&amp;portrait=0"
                  className="video-iframe"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  data-ready="true"
                  title="Vimeo Video"
                ></iframe>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className="bandbar"></div>
      {resultArr.length > 0 && (
        <>
          <SpeedFeedRecommendations />
          <div className="p-3">
            <Alert className="bg-info-subtle border border-info-subtle text-success-emphasis small">
              <FontAwesomeIcon className="pe-2" icon={fas.faNoteSticky} />
              <strong>Why so many Recommendations?</strong> In today's
              production world, rarely do shops cut the same material all day.
              We try to provide the best recommendation for the material you
              specify, then we provide alternatives that, though not as well
              suited to the task, are adequate to get the job done. By providing
              this service, it is our hope that you can spend your money a bit
              better, using the same blade for multiple jobs. This approach, in
              most cases, increases production by saving blade change time
              between jobs.
            </Alert>
          </div>
          <div className="text-center">
            <Row className="justify-content-center">
              <Col
                className="d-none d-sm-none d-md-flex justify-content-center gap-1"
                md={6}
              >
                <Button
                  style={{
                    backgroundColor: "rgb(6, 26, 43)",
                    position: "relative",
                  }}
                  onClick={handlePrintClick}
                  disabled={printLoading}
                >
                  {printLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  <span style={{ opacity: printLoading ? 0.5 : 1 }}>Print</span>
                  <FontAwesomeIcon
                    icon={fas.faPrint}
                    className="ms-1"
                    style={{ opacity: printLoading ? 0.5 : 1 }}
                  />
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(6, 26, 43)",
                    position: "relative",
                  }}
                  onClick={handleDownloadClick}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  <span style={{ opacity: loading ? 0.5 : 1 }}>Download</span>
                  <FontAwesomeIcon
                    icon={fas.faDownload}
                    className="ms-1"
                    style={{ opacity: loading ? 0.5 : 1 }}
                  />
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(6, 26, 43)" }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Email
                  <FontAwesomeIcon icon={fas.faEnvelope} className="ms-1" />
                </Button>
                <Button variant="warning" onClick={handleStartOverClick}>
                  Start Over
                  <FontAwesomeIcon icon={fas.faRepeat} className="ms-1" />
                </Button>
              </Col>

              <Col sm={12} className="d-md-none">
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Button
                      style={{
                        backgroundColor: "rgb(6, 26, 43)",
                        position: "relative",
                      }}
                      onClick={handlePrintClick}
                      disabled={printLoading}
                    >
                      {printLoading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      <span style={{ opacity: printLoading ? 0.5 : 1 }}>
                        Print
                      </span>
                      <FontAwesomeIcon
                        icon={fas.faPrint}
                        className="ms-1"
                        style={{ opacity: printLoading ? 0.5 : 1 }}
                      />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      style={{
                        backgroundColor: "rgb(6, 26, 43)",
                        position: "relative",
                      }}
                      onClick={handleDownloadClick}
                      disabled={loading}
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      <span style={{ opacity: loading ? 0.5 : 1 }}>
                        Download
                      </span>
                      <FontAwesomeIcon
                        icon={fas.faDownload}
                        className="ms-1"
                        style={{ opacity: loading ? 0.5 : 1 }}
                      />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      style={{ backgroundColor: "rgb(6, 26, 43)" }}
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      Email
                      <FontAwesomeIcon icon={fas.faEnvelope} className="ms-1" />
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Button variant="warning" onClick={handleStartOverClick}>
                      Start Over
                      <FontAwesomeIcon icon={fas.faRepeat} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
      <Modal centered show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Email Recommendation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="emal">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!!emailError}
              />
              <Form.Text>
                Separate multiple email addresses with a comma
              </Form.Text>
              {emailError && (
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "rgb(6, 26, 43)" }}
            onClick={handleEmailClick}
            disabled={emailLoading}
          >
            {emailLoading && <Spinner animation="border" size="sm" />}
            Email It
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastDisplay
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        variant={toastVariant}
      />
    </>
  );
};

export default SpeedAndFeedOutput;
