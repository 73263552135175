import React, { useState } from 'react';
import { FaCheckCircle, FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { Button, Card, Row, Col, ProgressBar, Badge } from 'react-bootstrap';
import Spacing from './Spacing';

type Review = {
  user: string;
  rating: number;
  title: string;
  date: string;
  location: string;
  color: string;
  size: string;
  verified: boolean;
  reviewText: string;
  helpful: number;
  categories: string[];  
};

const ReviewsComponent2 = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>('');  // Store only one selected filter

  const reviews: Review[] = [
    {
      user: 'Amarendra Sinha',
      rating: 4.5,
      title: 'It consumes very less electricity',
      date: '28 May 2024',
      location: 'India',
      color: 'Steel Onyx',
      size: '300 L',
      verified: true,
      reviewText:
        "Very smart look, cooling is superb, very convenient friendly interior designed. Giving excellent service, the interior lights so very well designed and fixed that one could see each and every corner clearly. There is no noise, it appears that it's switched off. All food stuff kept remains absolutely fresh even after weeks 😄 superb refrigerator.",
      helpful: 10,
      categories: ['Product quality', 'Design', 'Energy efficiency']
    },
    {
      user: 'Rohit Verma',
      rating: 5,
      title: 'Great Product for the Price',
      date: '10 June 2024',
      location: 'India',
      color: 'Silver',
      size: '320 L',
      verified: true,
      reviewText:
        "I am really happy with the purchase. The refrigerator is spacious and cools well. The design is sleek, and it fits perfectly in my kitchen. Energy efficiency is a big plus, and it keeps my food fresh for a long time. Highly recommended!",
      helpful: 15,
      categories: ['Product quality', 'Storage capacity', 'Energy efficiency']
    },
    {
      user: 'Meena Kapoor',
      rating: 3.5,
      title: 'Good but could be better',
      date: '22 May 2024',
      location: 'India',
      color: 'Black',
      size: '250 L',
      verified: false,
      reviewText:
        "The refrigerator looks great, and it works fine. However, I expected more in terms of energy efficiency. It's a little noisy at times. Overall, it's a good product, but there are better options in the market.",
      helpful: 5,
      categories: ['Cooling', 'Functionality', 'Energy efficiency']
    }
  ];

  const ratingStats = [
    { star: 5, percent: 53 },
    { star: 4, percent: 21 },
    { star: 3, percent: 7 },
    { star: 2, percent: 2 },
    { star: 1, percent: 17 }
  ];

  const reviewCategories = [
    { label: 'Product quality' },
    { label: 'Design' },
    { label: 'Storage capacity' },
    { label: 'Energy efficiency' },
    { label: 'Cooling' },
    { label: 'Functionality' },
    { label: 'Build quality' },
    { label: 'Value for money' }
  ];

  const toggleFilter = (category: string) => {
    setSelectedFilter(prevFilter => (prevFilter === category ? '' : category));
  };

  const filteredReviews = reviews.filter(review =>
    selectedFilter === '' || review.categories.includes(selectedFilter)
  );

  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-warning" />);
      } else if (i - 0.5 <= rating) {
        stars.push(<FaStarHalfAlt key={i} className="text-warning" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-warning" />);
      }
    }
    return stars;
  };

  return (
    <div className="container mt-5">

      <Row className=" pb-0 mb-0">
        <Col md={5}>
          <h4 className="fw-bold">Customer reviews</h4>
          <h5 className="text-muted">3.9 out of 5</h5>
          <p>5,025 global ratings</p>
          {ratingStats.map((stat, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <span style={{ width: '80px' }}>{stat.star} star</span>
              <ProgressBar 
                now={stat.percent} 
                className="mx-2" 
                style={{ width: '200px' }} 
                variant="danger"  
              />
              <span>{stat.percent}%</span>
            </div>
          ))}

          <Spacing height={10}/>
          <div className="mt-4">
            <h5 className="fw-bold">Review this product</h5>
            <p>Share your thoughts with other customers</p>
            <Button variant="outline-danger" className="rounded-pill px-4 py-2" style={{ width: 'auto' }}>
              Write a product review
            </Button>
          </div>
        </Col>

        <Col md={7}>
          <h6 className="fw-bold">Customers say</h6>
          <p className="text-muted">
            Customers like the quality and design of the refrigerator. They mention it's a good choice and looks very premium. Customers are also happy with the storage capacity and energy efficiency. However, some customers disagree on the cooling, functionality, build quality, and value for money.
          </p>

          <div className="d-flex flex-wrap mb-4">
            {reviewCategories.map((category, index) => (
              <div key={index} className="d-flex align-items-center me-3 mb-2">
                {selectedFilter === category.label && (
                  <FaCheckCircle 
                    className="text-success me-2" 
                    size={16}  
                    style={{ minWidth: '16px' }}  
                  />
                )}
                <Button 
                  variant="link" 
                  className={`p-0 text-dark ${selectedFilter === category.label ? 'fw-bold' : ''}`} 
                  onClick={() => toggleFilter(category.label)}
                >
                  {category.label}
                </Button>
                {index < reviewCategories.length - 1 && <span className="mx-1">|</span>}
              </div>
            ))}
          </div>

 
          <div className="mt-3">
            {filteredReviews.length > 0 ? (
              filteredReviews.map((review, index) => (
                <Card key={index} className="mb-4 shadow-sm border-0">
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='d-flex justify-center align-items-center mb-1'>
                          <img
                            src={`https://via.placeholder.com/50`} 
                            alt="user-profile"
                            className="rounded-circle user-icon"
                          />
                          <h6 className="mb-0 fw-bold ms-2">{review.user}</h6>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          {renderStars(review.rating)}
                          <span className="ms-2 fw-bold">{review.title}</span>
                        </div>
                        <p className="text-muted small">
                          Reviewed in {review.location} on {review.date} <br />
                          Colour: {review.color} | Size: {review.size}{' '}
                          {review.verified && <Badge bg="success" className="ms-1">Verified Purchase</Badge>}
                        </p>
                        <p>{review.reviewText}</p>
                        <div className="d-flex">
                          <Button variant="outline-secondary" className="me-2 rounded-pill">
                            Helpful ({review.helpful})
                          </Button>
                          <Button variant="link" className="text-muted p-0">Report</Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No reviews available for the selected filter.</p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewsComponent2;
