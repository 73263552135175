import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { baseApiURL } from "../constants";

const jwtToken = localStorage.getItem("jwtToken");

const axiosInstance = axios.create({
  baseURL: baseApiURL,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": jwtToken,
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>) => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      request.headers.set("x-access-token", jwtToken);
    }
    return request;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
