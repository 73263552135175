import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface IModelDropDownProps {
  models: { sid: number; model: string }[];
  onModelSelect: (sid: number) => void;
  selectedModelSid: number;
}

const ModelDropDown: React.FC<IModelDropDownProps> = ({
  models,
  onModelSelect,
  selectedModelSid,
}) => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const sid = parseInt(event.target.value);
    if (sid === selectedModelSid) {
      onModelSelect(0); // Clear selection if the same model is selected again
    } else {
      onModelSelect(sid);
    }
  };

  return (
    <Form.Group controlId="modelSelect" className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
        Machine Model
      </Form.Label>
      <Form.Select
        value={selectedModelSid || ""}
        onChange={handleSelect}
        aria-label="Select Model"
      >
        <option value="">Select Model</option>
        {models.map((item) => (
          <option key={item.sid} value={item.sid}>
            {item.model}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default ModelDropDown;
