import React from "react";
import { FaHandPointRight } from "react-icons/fa";

const PricingOptions6: React.FC = () => {
  const pricingData = [
    { size: "1 Blade", price: "$19.35", discount: null, isActive: true },
    { size: "Order 2 to 4", price: "$19.12", discount: "2%", isActive: false },
    { size: "Order 2 to 4", price: "$19.12", discount: "4%", isActive: false },
    { size: "Order 2 to 4", price: "$19.12", discount: "6%", isActive: false },
    { size: "Order 2 to 4", price: "$19.12", discount: "8%", isActive: false },
    { size: "Order 2 to 4", price: "$19.12", discount: "12%", isActive: false },
  ];

  return (
    <div className="cart-card">
      <div className="cart-card-body6 card-inner-padding">
        <div className="best-for-container">
          <p className="pricing-title">PRICING OPTIONS</p>
          <table className="pricing-table">
            <thead>
              <tr>
                <td
                  className="pricing-size fw-bold"
                  style={{
                    paddingLeft: "0",
                    width: "50%",
                  }}
                >
                  Qty
                </td>
                <td
                  className="pricing-price text-right fw-bold"
                  style={{ paddingRight: "10px", width: "20%" }}
                >
                  Price
                </td>
                <td
                  className="discount-percentage text-end fw-bold pe-1"
                  style={{ width: "30%", color: "green" }}
                >
                  Savings
                </td>
              </tr>
            </thead>
            <tbody>
              {pricingData.map((option, index) => (
                <tr
                  key={index}
                  className={`pricing-row ${
                    option.isActive ? "active-pricing-option" : ""
                  }`}
                  style={{ position: "relative" }}
                >
                  <td
                    className="pricing-size"
                    style={{
                      paddingLeft: option.isActive ? "24px" : "0",
                      width: "50%",
                    }}
                  >
                    {option.isActive && (
                      <span
                        className="active-icon"
                        style={{
                          position: "absolute",
                          left: "12px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <FaHandPointRight />
                      </span>
                    )}
                    {option.size}
                  </td>
                  <td
                    className="pricing-price text-right"
                    style={{ paddingRight: "10px", width: "20%" }}
                  >
                    {option.price}
                  </td>
                  <td
                    className="discount-percentage text-end pe-1"
                    style={{ width: "30%", color: "green" }}
                  >
                    {option.discount && (
                      <span
                        style={{ display: "inline-flex", alignItems: "end" }}
                      >
                        save {option.discount}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Adding green text for free shipping */}
          <p
            className="free-shipping-text"
            style={{ color: "green", textAlign: "center", marginTop: "10px" }}
          >
            Order 2 to 4 and Save 2%
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingOptions6;
