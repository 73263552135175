import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface BundleSelectionProps {
  stock: number;
  shape: string;
  onAcrossChange: (value: number | null) => void;
  onTallChange: (value: number | null) => void;
}

const BundleSelection: React.FC<BundleSelectionProps> = ({
  stock,
  shape,
  onAcrossChange,
  onTallChange,
}) => {
  const [bundle, setBundle] = useState<string>("");

  useEffect(() => {
    const firstOption = getBundleOptions(stock, shape)[0];
    if (firstOption) {
      setBundle(firstOption.value);
    }
  }, [stock, shape]);

  const handleBundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBundle(e.target.value);
  };

  const getBundleOptions = (stock: number, shape: string) => {
    if (stock === 1 && shape === "solid-sq") {
      return [
        {
          value: "squarebundle",
          imgSrc: require("../assets/images/bundle-square.png"),
          alt: "Square Bundle",
        },
      ];
    } else if (stock === 1 && shape === "solid-bar") {
      return [
        {
          value: "stackedbundle",
          imgSrc: require("../assets/images/bundle-bar-stack.png"),
          alt: "Stacked Bundle",
        },
        {
          value: "tightbundle",
          imgSrc: require("../assets/images/bundle-bar-tight.png"),
          alt: "Tight Bundle",
        },
      ];
    } else if (stock === 1 && shape === "solid-rect-tall") {
      return [
        {
          value: "tallbundle",
          imgSrc: require("../assets/images/bundle-rect-tall.png"),
          alt: "Tall Bundle",
        },
        {
          value: "widebundle",
          imgSrc: require("../assets/images/bundle-rect-wide.png"),
          alt: "Wide Bundle",
        },
      ];
    } else if (stock === 1 && shape === "solid-rect-wide") {
      return [
        {
          value: "tallbundle",
          imgSrc: require("../assets/images/bundle-rect-tall.png"),
          alt: "Tall Bundle",
        },
        {
          value: "widebundle",
          imgSrc: require("../assets/images/bundle-rect-wide.png"),
          alt: "Wide Bundle",
        },
      ];
    } else if (stock === 2 && shape === "tube-sq") {
      return [
        {
          value: "squarebundle",
          imgSrc: require("../assets/images/bundle-square.png"),
          alt: "Square Bundle",
        },
      ];
    } else if (stock === 2 && shape === "tube-bar") {
      return [
        {
          value: "stackedbundle",
          imgSrc: require("../assets/images/bundle-bar-stack.png"),
          alt: "Stacked Bundle",
        },
        {
          value: "tightbundle",
          imgSrc: require("../assets/images/bundle-bar-tight.png"),
          alt: "Tight Bundle",
        },
      ];
    } else if (stock === 2 && shape === "tube-rect-tall") {
      return [
        {
          value: "tallbundle",
          imgSrc: require("../assets/images/bundle-rect-tall.png"),
          alt: "Tall Bundle",
        },
        {
          value: "widebundle",
          imgSrc: require("../assets/images/bundle-rect-wide.png"),
          alt: "Wide Bundle",
        },
      ];
    } else if (stock === 2 && shape === "tube-rect-wide") {
      return [
        {
          value: "tallbundle",
          imgSrc: require("../assets/images/bundle-rect-tall.png"),
          alt: "Tall Bundle",
        },
        {
          value: "widebundle",
          imgSrc: require("../assets/images/bundle-rect-wide.png"),
          alt: "Wide Bundle",
        },
      ];
    } else if (stock === 3 && shape === "struct-angle") {
      return [
        {
          value: "anglebundle",
          imgSrc: require("../assets/images/bundle-angle.png"),
          alt: "Angle Bundle",
        },
      ];
    }
    return [];
  };

  const bundleOptions = getBundleOptions(stock, shape);

  return (
    <Form.Group controlId="shape" className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
        Select Bundle
      </Form.Label>
      <span className="form-text text-danger">
        - Choose cutting configuration and enter number of pieces
      </span>

      <Row style={{ minHeight: "160px" }}>
        {bundleOptions.map((option) => (
          <Col key={option.value} xs={3}>
            <Form.Check
              type="radio"
              name="bundle"
              value={option.value}
              checked={bundle === option.value}
              onChange={handleBundleChange}
              label={
                <img
                  src={option.imgSrc}
                  width="87"
                  height="89"
                  alt={option.alt}
                />
              }
            />
          </Col>
        ))}

        <Col xs={8} className="ps-5">
          <Form.Group>
            <Form.Label className="mb-0 fw-normal fs-6">
              <FontAwesomeIcon className="fs-3" icon={fas.faArrowsLeftRight} />{" "}
              How Many Across?
            </Form.Label>
            <Form.Control
              type="number"
              name="across"
              placeholder="Number of pieces?"
              onChange={(e) => {
                const value = e.target.value !== "" ? parseInt(e.target.value, 10) : null;
                onAcrossChange(value);
              }}
              min={0}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="mb-0 fw-normal fs-6">
              <FontAwesomeIcon className="fs-3" icon={fas.faArrowsUpDown} /> How
              Many Tall?
            </Form.Label>
            <Form.Control
              type="number"
              name="tall"
              placeholder="Number of pieces?"
              onChange={(e) => {
                const value = e.target.value !== "" ? parseInt(e.target.value, 10) : null;
                onTallChange(value);
              }}
              min={0}
            />
          </Form.Group>
        </Col>
      </Row>
      <span className="form-text ps-1">
        {" "}
        Calculations assume cutting travels from top to bottom
      </span>
    </Form.Group>
  );
};

export default BundleSelection;
