import React from 'react';
import ReviewsComponent2 from './ReviewsComponent2';

const ProductReviews2: React.FC = () => {

  return (
    <>
    
      <ReviewsComponent2 />
    </>
  );
};

export default ProductReviews2;
