import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFlagUsa, FaTruck, FaCheckCircle } from 'react-icons/fa';

const FeaturesBar: React.FC = () => {
  return (
    <div className="features-bar" style={{ backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center text-center">
          {/* First Feature: Custom Welded Blades */}
          <Col xs={12} md={4} className="d-flex justify-content-center align-items-center position-relative mb-2 mb-md-0">
            <FaFlagUsa color="#DA002A" size={16} className="me-2" />
            <span className="feature-text" style={{ fontWeight: '500', fontSize: '12px' }}>CUSTOM WELDED BLADES IN USA</span>
            <div className="d-none d-md-block" style={{ position: 'absolute', right: '-20px', height: '30px', borderLeft: '1px solid #ddd' }} />
          </Col>

          {/* Second Feature: 100% Satisfaction */}
          <Col xs={12} md={4} className="d-flex justify-content-center align-items-center position-relative mb-2 mb-md-0">
            <FaCheckCircle color="#DA002A" size={16} className="me-2" />
            <span className="feature-text" style={{ fontWeight: '500', fontSize: '12px' }}>100% SATISFACTION GUARANTEE</span>
            <div className="d-none d-md-block" style={{ position: 'absolute', right: '-20px', height: '30px', borderLeft: '1px solid #ddd' }} />
          </Col>

          {/* Third Feature: Same-day Shipping */}
          <Col xs={12} md={4} className="d-flex justify-content-center align-items-center">
            <FaTruck color="#DA002A" size={16} className="me-2" />
            <span className="feature-text" style={{ fontWeight: '500', fontSize: '12px' }}>SAME-DAY SHIPPING BEFORE 3PM</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesBar;
