import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import bannerImage from '../assets/images/banner3.png';  // Adjust path based on your structure

const Banner3: React.FC = () => {
  const fullStars = 4;
  const partialStarPercentage = 20; // 20% filled for 4.2 rating

  return (
    <div 
      className="container-custom" 
      style={{
        position: 'relative',  // Ensure the container has relative positioning for the overlay
        backgroundImage: `linear-gradient(to right, rgba(6, 26, 43, 0.8), rgba(6, 26, 43, 0.7)), url(${bannerImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      
      }}
    >
      {/* No need for additional overlay since gradient is added in the background itself */}
      
      {/* Content should be above the overlay */}
      <Container style={{ position: 'relative', zIndex: 2 }}>
        <div className='zoom-out3'>
          <Row className="mt-2">
            <Col>
              <Breadcrumb className="breadcrumb-custom">
                <Breadcrumb.Item href="#">Band Saw Blades</Breadcrumb.Item>
                <Breadcrumb.Item active>Qsaw 501 IC/Structural Brand Saw Blade</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
            <div>
              <h1 className="product-name1">Qsaw AW 501 IC/Structural Band Saw Blades</h1>
            </div>
            <div className="text-end mt-2 mt-md-0">
              <div className="rating d-flex align-items-center">
                <span className="me-2">4.2</span>
                {[...Array(fullStars)].map((_, index) => (
                  <FaStar key={index} color="#ffc107" />
                ))}
                <div className="position-relative" style={{ display: 'inline-block', width: '16px', height: '16px' }}>
                  <FaStar className="position-absolute" style={{ top: 0, left: 0 }} />
                  <FaStar 
                    color="#ffc107" 
                    className="position-absolute" 
                    style={{ top: 0, left: 0, clipPath: `inset(0 ${100 - partialStarPercentage}% 0 0)` }} 
                  />
                </div>
                <span className="rating-text ms-2">Ratings (223)</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Banner3;
