import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Spacing from '../Components/Spacing';
import Loader from '../Components/loader';
import ProductImage2 from '../Components/ProductImage2';
import Quantity6 from '../Components/Quantity6';
import PricingOptions6 from '../Components/PricingOptions6';
import BestForList from '../Components/BestForList';
import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/img2.jpg';
import img3 from '../assets/images/img3.jpg';
import img4 from '../assets/images/img4.jpg';
import Banner3 from '../Components/Banner3';
import FeaturesBar from '../Components/FeaturesBar';
import ProductReviews2 from '../Components/ProductReviews2';

const ProductInfo = React.lazy(() => import('../Components/ProductInfo'));
const SpecificationTable = React.lazy(() => import('../Components/SpecificationTable'));
const Benefits = React.lazy(() => import('../Components/Benefits'));
const ApplicationSection = React.lazy(() => import('../Components/ApplicationSection'));
const DownloadableResources = React.lazy(() => import('../Components/DownloadableResources'));
const RelatedProducts2 = React.lazy(() => import('../Components/RelatedProducts2'));
const StickyFooter = React.lazy(() => import('../Components/StickyFooter'));
const Footer = React.lazy(() => import('../Components/Footer'));
const ProductDetail4 = React.lazy(() => import('../Components/ProductDetail4'));
const VideoPlayer = React.lazy(() => import('../Components/VideoPlayer'));

const Home10 = React.memo(() => {

  const [selectedImage, setSelectedImage] = useState(img1);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = viewportWidth < 768;
  const isTabView = viewportWidth >= 768 && viewportWidth <= 991;

  const handleSelectImage = () => {
    const images = [img1, img2, img3, img4];
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(randomImage);
  };

  const handleSelect = (eventKey) => {
    if (eventKey === 'blog') {
      window.location.href = 'https://bandsawblog.com/';
    }
  };

  const renderProductDetails = useCallback(() => (
    <Suspense fallback={<Loader />}>
      <ProductInfo />
    </Suspense>
  ), []);

  const renderSpecificationAndBenefits = useCallback(() => (
    <Row>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <SpecificationTable />
        </Suspense>
      </Col>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <Benefits />
          <ApplicationSection />
        </Suspense>
      </Col>
    </Row>
  ), []);

  return (
    <div>
      <FeaturesBar />
      <Banner3 />
      <div className="sectionc1">
        <Container>
          <Suspense fallback={<Loader />}>
            <ProductDetail4
              onBladeWidthChange={handleSelectImage}
              onSelectionChange={handleSelectImage} 
            />
          </Suspense>
          <Spacing height={isMobile || isTabView ? 8 : 2} />
          <Row className={isMobile || isTabView ? '' : 'flex-container'}>
          <Col xs={12} md={6} lg={3} className={`${isMobile ? 'd-none' : 'd-flex'} p-2`}>
              <ProductImage2 selectedImage={selectedImage} />
            </Col>
            <Col xs={12} md={6} lg={2} className={isMobile || isTabView ? 'p-2' : 'd-flex p-2'}>
              <BestForList />
            </Col>
            <Col xs={12} md={6} lg={4} className={isMobile || isTabView ? 'p-2' : 'd-flex p-2'}>
              <Quantity6 />
            </Col>
            <Col xs={12} md={6} lg={3} className={isMobile || isTabView ? 'p-2' : 'd-flex p-2'}>
              <PricingOptions6 />
            </Col>
          </Row>
          <Row>
            <Col>
              <Suspense fallback={<Loader />}>
                {/* CustomTable8 can go here if needed */}
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='sectionc3'>
        <Container>
          <div className="description-container">
            <div className="cart-card2 my-4 cart-card-tab-data">
              <div className="cart-card-body">
                <Tab.Container defaultActiveKey="details" onSelect={handleSelect}>
                  <Nav variant="tabs" className="custom-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="details">{isMobile || isTabView ? "SPECS" : "FEATURES & SPECS"}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="specs">{isMobile || isTabView ? "OVERVIEW" : "PRODUCT OVERVIEW"}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">{isMobile || isTabView ? "RESOURCES" : "DOWNLOADABLE RESOURCES"}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reviews">{isMobile || isTabView ? "REVIEWS" : "REVIEWS"}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="blog">{isMobile || isTabView ? "BLOGS" : "BLOG"}</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="details">
                      {renderProductDetails()}
                      {renderSpecificationAndBenefits()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="specs">
                      <Suspense fallback={<Loader />}>
                        <VideoPlayer />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <Suspense fallback={<Loader />}>
                        <DownloadableResources />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="reviews">
                      {/* <ProductReviews /> */}
                      <ProductReviews2 />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='sectionc4'>
        <Container>
          <Suspense fallback={<Loader />}>
            <Spacing height={20} />
            <RelatedProducts2 />
          </Suspense>
        </Container>
      </div>
      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
});

export default Home10;
