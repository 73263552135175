import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndPoints } from "../../constants";
import axiosInstance from "../../utils/servicecall";

interface IMachineData {
  machine: string;
}

interface IModelData {
  sid: number;
  model: string;
}

export interface IGradeData {
  gid: number;
  grade: string;
}

export interface IMaterialGradeData {
  material: string;
  matid: number;
  gradeList: IGradeData[];
}

export interface IBladeWidthData {
  inch: number;
  widthid: number;
}

interface IMachineDetail {
  sysId: number;
  machine: string;
  model: string;
  bladeSize: string;
  bladeWidth: string;
  multiplier: number;
  done: any;
  urlkey: string;
  bladeFeet: number;
  bladeInches: number;
  bladeFracInch: string;
  bladeDecimal: number;
  lengthInches: number;
  verified: number;
  verifyDt: string;
  verifyBy: string;
  custId: number | null;
  bladewidthMin: number;
  bladewidthMax: number;
  partCategory: number;
  laborRate: number;
  minhours: number;
  inhouse: number;
  machineType: string | null;
  manualUrl: string | null;
  beltId: number | null;
  guideId: number | null;
  brushId: number | null;
  lastUpdatedOn: string;
}

interface IResultArr {
  bladeseriesid: number;
  rateReduction: number;
  stockKey: number;
  materialDimensions: string;
  areatocut: number;
  metadata: string;
  stockData: IStockData;
  bladeSizeId: number;
  bladeWidthData: IBladeWidthDataList;
  bandTypeData: IBandTypeData;
  materialDescription: string;
  heatTreatedDescription: string;
  cutRateRangeLow: number;
  cutRateRangeHigh: number;
  rateHigh: number;
  rateMedium: number;
  rateSaver: number;
  speedRange: number;
  fallRate: number;
  timeHigh: number;
  timeMedium: number;
  timeSaver: number;
  groupid: string;
  pages: IPage[];
  stepAdjust: number;
  tpRange: any[];
  tpArray: any[];
  tpStruct: ITpStruct;
  seriesLabel: string;
}

interface IStockData {
  rateId: number;
  speed: number;
  stock: number;
  lowCutRate: number;
  highCutRate: number;
  gradeId: number;
  matid: number;
  updDt: string;
  grade: string;
  material: string;
  stockdetail: string;
  firstName: string | null;
  lastName: string | null;
}

interface IBladeWidthDataList {
  sizeId: number;
  bladeSize: string;
  multiplier: number;
  bladeSizeNum: number;
  updDt: string;
}

interface IBandTypeData {
  band: string;
  speedfactor: number;
  feedfactor: number;
}

interface IPage {
  pagename: string;
  groupname: string;
  qseries: string;
}

interface ITpStruct {
  best: ITpRecommendation;
  option1: ITpRecommendation;
  option2: ITpRecommendation;
}

interface ITpRecommendation {
  descr: string;
  id: number;
  bladeDetailForCart: IBladeDetailForCart[];
}

interface IBladeDetailForCart {
  bladeId: number;
  thickness: number;
}
interface ICutQuote {
  status: boolean;
  message: string;
  areaToCut: number;
  cuttime: number;
  pricePerCut: number;
  maxCutsPerHour: number;
  forJob: number;
  fallRate: number;
  pieceCount: number;
  theCutRate: number;
  sqInPricePerCut: number;
  targetRateForMachine: number;
  quotaPricePerCut: number;
}

export const fetchMachineList = createAsyncThunk(
  apiEndPoints["GET_MANUFACTURER_LIST"],
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get<IMachineData[]>(
        apiEndPoints["GET_MANUFACTURER_LIST"]
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || "Records not found"
      );
    }
  }
);

export const fetchModel = createAsyncThunk(
  apiEndPoints["GET_MODEL_LIST"],
  async (machine: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get<IModelData[]>(
        `${apiEndPoints["GET_MODEL_LIST"]}?machine=${machine}`
      );
      if (response.status === 200) return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || "Records not found "
      );
    }
  }
);

export const fetchMaterialGradeList = createAsyncThunk(
  apiEndPoints["GET_MATERIAL_GRADE"],
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get<IMaterialGradeData[]>(
        apiEndPoints["GET_MATERIAL_GRADE"]
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || "Records not found"
      );
    }
  }
);

export const fetchBladeWidthList = createAsyncThunk(
  apiEndPoints["GET_BLADE_WIDTH"],
  async (
    { machineModel, matid }: { machineModel: number; matid: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get<IBladeWidthData[]>(
        `${apiEndPoints["GET_BLADE_WIDTH"]}?machineModel=${machineModel}&matid=${matid}`
      );
      if (response.status === 200) return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || "Records not found"
      );
    }
  }
);

export const calculateSpeedFeed = createAsyncThunk(
  apiEndPoints["CALCULATE_SPEED_FEED"],
  async (reqObj: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        apiEndPoints["CALCULATE_SPEED_FEED"],
        reqObj,
        reqObj.generatePDF ? { responseType: "blob" } : {}
      );
      // If it's a PDF request, return the blob data, else return the calculation result
      return reqObj.generatePDF ? { pdfBlob: response.data } : response.data;
    } catch (error: any) {
      console.log("##############",error)
      const errorMessage =
        error.response?.data?.error || error.message || "Invalid Request";
      return rejectWithValue(errorMessage);
    }
  }
);

interface ISpeedFeedData {
  machineListData: IMachineData[];
  modelListData: IModelData[];
  materialGradeData: IMaterialGradeData[];
  bladeWidthData: IBladeWidthData[];
  calculateResult: {
    resultArr: IResultArr[];
    cutQuote: ICutQuote;
    machineDetail: IMachineDetail;
  };
  dataLoader: boolean;
  calculateLoading: boolean;
  error: string | null;
}

const initialState: ISpeedFeedData = {
  machineListData: [],
  modelListData: [],
  materialGradeData: [],
  bladeWidthData: [],
  calculateResult: {
    resultArr: [],
    cutQuote: {
      status: false,
      message: "",
      areaToCut: 0,
      cuttime: 0,
      pricePerCut: 0,
      maxCutsPerHour: 0,
      forJob: 0,
      fallRate: 0,
      pieceCount: 0,
      theCutRate: 0,
      sqInPricePerCut: 0,
      targetRateForMachine: 0,
      quotaPricePerCut: 0,
    },
    machineDetail: {
      sysId: 0,
      machine: "",
      model: "",
      bladeSize: "",
      bladeWidth: "",
      multiplier: 0,
      done: null,
      urlkey: "",
      bladeFeet: 0,
      bladeInches: 0,
      bladeFracInch: "",
      bladeDecimal: 0,
      lengthInches: 0,
      verified: 0,
      verifyDt: "",
      verifyBy: "",
      custId: null,
      bladewidthMin: 0,
      bladewidthMax: 0,
      partCategory: 0,
      laborRate: 0,
      minhours: 0,
      inhouse: 0,
      machineType: null,
      manualUrl: null,
      beltId: null,
      guideId: null,
      brushId: null,
      lastUpdatedOn: "",
    },
  },
  dataLoader: false,
  calculateLoading: false,
  error: null,
};

const speedFeedManagerSlice = createSlice({
  name: "speedFeedManager",
  initialState,
  reducers: {
    setModelListEmpty(state) {
      state.modelListData = [];
    },
    setMaterialGradeListEmpty(state) {
      state.materialGradeData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineList.pending, (state) => {
        state.dataLoader = true;
        state.error = null;
      })
      .addCase(fetchMachineList.fulfilled, (state, action) => {
        state.dataLoader = false;
        state.machineListData = action.payload;
      })
      .addCase(fetchMachineList.rejected, (state, action) => {
        state.dataLoader = false;
        state.error = action.payload as string;
      })
      .addCase(fetchModel.fulfilled, (state, action) => {
        state.dataLoader = false;
        state.modelListData = action.payload || [];
      })
      .addCase(fetchMaterialGradeList.fulfilled, (state, action) => {
        state.materialGradeData = action.payload;
      })
      .addCase(fetchBladeWidthList.fulfilled, (state, action) => {
        state.bladeWidthData = action.payload || [];
      })
      .addCase(calculateSpeedFeed.pending, (state) => {
        state.calculateLoading = true;
        state.error = null;
      })
      .addCase(calculateSpeedFeed.fulfilled, (state, action) => {
        if (!action.payload.pdfBlob) {
          // Update the store state only if it's not a PDF download
          state.calculateLoading = false;
          state.calculateResult = action.payload;
        }
      })
      .addCase(calculateSpeedFeed.rejected, (state, action) => {
        state.calculateLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setModelListEmpty, setMaterialGradeListEmpty } =
  speedFeedManagerSlice.actions;
export default speedFeedManagerSlice.reducer;
