import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface NotificationToastProps {
  show: boolean;
  onClose: () => void;
  message: string;
  variant: "success" | "error";
}

const ToastDisplay: React.FC<NotificationToastProps> = ({
  show,
  onClose,
  message,
  variant,
}) => {
  const bgVariant = variant === "error" ? "danger" : "success";
  const headerTitle = variant === "error" ? "Error" : "Success";

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast
        show={show}
        onClose={onClose}
        delay={3000}
        autohide
        bg={bgVariant}
        style={{ minWidth: "250px" }}
      >
        <Toast.Header>
          <strong className="me-auto">{headerTitle}</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body className="text-white">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastDisplay;
