import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";
import { Alert } from "react-bootstrap";

const SpeedBanner = () => {
  return (
    <>
      <div className="speed-banner-top d-md-block d-none">
        <h1 className="text-center text-white">Speed and Feed Calculator</h1>
        <div className="banner-inner">
          <div className="my-2 speed-detail-inner">
            <img
              src={require("../assets/images/snf-print.jpg")}
              className="image-style"
              alt="Speed Banner"
            />
            <div className="mt-4 bg-white">
              <div className="col pt-2 speed-banner-content">
                <FontAwesomeIcon
                  icon={fas.faStopwatch}
                  className="watch-icon"
                />
                In <span className="highlight-text">10 Clicks</span> and under{" "}
                <span className="highlight-text">30 seconds</span>
                <br />
                receive a complete sawing analysis.
              </div>
              <div className="col pt-2 pb-3 small banner-details">
                <div className="row">
                  <div className="col-6">
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Blade Recommendations
                    <br />
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Tooth Pitch Recommendations
                    <br />
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Cut Rate Range
                  </div>
                  <div className="col-6">
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Speed Range
                    <br />
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Completion Times
                    <br />
                    <FontAwesomeIcon
                      icon={fas.faCheck}
                      className="text-success"
                      aria-hidden="true"
                    ></FontAwesomeIcon>{" "}
                    Metal Cutting Estimate{" "}
                    <img
                      src={require("../assets/images/new.png")}
                      alt="New Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bandbar"></div>
      <div className="p-3">
        <Alert className="text-warning-emphasis bg-warning-subtle border border-warning-subtle small">
          <FontAwesomeIcon className="pe-2" icon={fas.faMessageSmile} />
          We are happy to provide up to 3 recommendations per month, however, if
          you log in we will save the machines you enter here to your account to
          make ordering and using the calculator easier in the future!
          <br />
          <strong>
            You have 3 recommendations left before you will need to log in.
          </strong>
        </Alert>
      </div>
    </>
  );
};

export default SpeedBanner;
