import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface IMaterialShapeSelectorProps {
  matshape: number;
  onShapeSelect: (shapeId: string) => void;
}

const MaterialShapeSelector: React.FC<IMaterialShapeSelectorProps> = ({
  matshape,
  onShapeSelect,
}) => {
  const getShapeOptions = () => {
    switch (matshape) {
      case 1: // Solid
        return [
          {
            id: "solid-sq",
            src: require("../assets/images/solid-sq.png"),
            alt: "Solid Square",
          },
          {
            id: "solid-bar",
            src: require("../assets/images/solid-round.png"),
            alt: "Solid Round",
          },
          {
            id: "solid-rect-tall",
            src: require("../assets/images/solid-rect-tall.png"),
            alt: "Solid Rectangular Tall",
          },
          {
            id: "solid-rect-wide",
            src: require("../assets/images/solid-rect-wide.png"),
            alt: "Solid Rectangular Wide",
          },
        ];
      case 2: // Tubular
        return [
          {
            id: "tube-sq",
            src: require("../assets/images/tube-sq.png"),
            alt: "Tube Square",
          },
          {
            id: "tube-bar",
            src: require("../assets/images/tube-round.png"),
            alt: "Tube Round",
          },
          {
            id: "tube-rect-tall",
            src: require("../assets/images/tube-rect-tall.png"),
            alt: "Tube Rectangular Tall",
          },
          {
            id: "tube-rect-wide",
            src: require("../assets/images/tube-rect-wide.png"),
            alt: "Tube Rectangular Wide",
          },
        ];
      case 3: // Structural
        return [
          {
            id: "struct-angle",
            src: require("../assets/images/struct-angle.png"),
            alt: "Structural Angle",
          },
        ];
      default:
        return [];
    }
  };

  const shapeOptions = getShapeOptions();

  return (
    <>
      <Form.Group controlId="shape" className="machineDrop">
        <Form.Label className="machineLabel fw-normal fs-6">
          <span className="icon-drop">
            <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
          </span>
          Material Shape
        </Form.Label>
        <Row>
          {shapeOptions.map((shape) => (
            <Col xs={3} key={shape.id}>
              <Form.Check
                type="radio"
                name="shape"
                id={shape.id}
                value={shape.id}
                label={
                  <img src={shape.src} alt={shape.alt} width="50" height="50" />
                }
                onChange={() => onShapeSelect(shape.id)}
              />
            </Col>
          ))}
        </Row>
        <span className="text-danger form-text px-3">
        - Select Shape and cutting orientation
      </span>
      </Form.Group>
     
    </>
  );
};

export default MaterialShapeSelector;
