import React, { Suspense } from "react";
import Loader from "../Components/loader";
import { Alert, Container } from "react-bootstrap";
import SpeedAndFeed from "../Components/SpeedAndFeed";
import "../assets/css/front.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";
import SpeedBanner from "../Components/SpeedBanner";
import SpeedAndFeedOutput from "../Components/SpeedAndFeedOutput";
import { useLocation } from "react-router-dom";
import FeaturesBar from "../Components/FeaturesBar";
const Footer = React.lazy(() => import("../Components/Footer"));
const StickyFooter = React.lazy(() => import("../Components/StickyFooter"));

const SpeedFeedHome = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pm = queryParams.get("pm");
  return (
    <div>
      <Container>
        {pm === "1" ? (
          <>
          <FeaturesBar />
          <SpeedAndFeedOutput />
          </>
        ) : (
          <>
          <FeaturesBar />
            <SpeedBanner />
            <SpeedAndFeed />
            <div className="p-3">
              <Alert className="bg-info-subtle border border-info-subtle text-success-emphasis small">
                <FontAwesomeIcon className="pe-2" icon={fas.faNoteSticky} />
                The Sawblade.com bandsaw speed and feed wizard uses standard
                industry calculations to not only estimate cutting times for
                different metals and alloys, but also to provide you with
                recommendations for band saw blade tooth pitch for your
                application. This wizard is provided as a starting point only,
                it is not the end all, be all, for cutting. There are many
                factors above and beyond the calculations that will determine
                your actual cutting speed and feed pressure. Factors such as
                your machine, its horsepower, its feed system, whether it cuts
                guillotine style (horizontal/vertical), pivot style, or canted.
                Lubrication also affects cutting times. <br />
                <em>
                  Since there is no way we can account for all factors, we are
                  sticking to the basics; we try our best to get you started,{" "}
                  <strong>
                    but your common sense, experience, and results should
                    determine your final settings
                  </strong>
                  .
                </em>
              </Alert>
            </div>
          </>
        )}
      </Container>

      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
};

export default SpeedFeedHome;
