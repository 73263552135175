import { configureStore } from '@reduxjs/toolkit';
import speedFeedManagerReducer from './features/speedFeedManagerSlice';

export const store = configureStore({
  reducer: {
    speedFeedManager: speedFeedManagerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;