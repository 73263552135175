import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import './index.scss';
import './assets/css/front.css';
import Header from './Components/Header';
import VideoPlayer from './Components/VideoPlayer';
import SpeedFeedHome from './Screens/SpeedFeedHome';
import Home10 from './Screens/Home10';
import TopHeader from './Components/TopHeader';


function App() {
  return (
    <Router>
      <div className="App">
        <TopHeader />
        <Header /> 
        <Routes>
          <Route path="/" element={<Home10 />} />
          <Route path="/videos" element={<VideoPlayer />} />
          <Route path="/speedandfeed" element={<SpeedFeedHome />} />
          <Route path="/sawbladedata" element={<SpeedFeedHome />} />
          <Route path="/home10" element={<Home10 />} />
 

        </Routes>
      </div>
    </Router>
  );
}

export default App;
