import React from "react";

const ProductImage2: React.FC<{ selectedImage: string }> = ({
  selectedImage,
}) => {
  return (
    <div className="cart-card">
      <div className="cart-card-body6 card-inner-padding">
          <img
            src={selectedImage}
            alt="Product"
            className="img-fluid p-image p-image2"
          />
      </div>
    </div>
  );
};

export default ProductImage2;
