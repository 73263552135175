export const decimalToTime = (d: number) => {
    d = d * 60; // Convert decimal to minutes
    const h = Math.floor(d / 3600); // Hours
    const m = Math.floor((d % 3600) / 60); // Minutes
    const s = Math.floor((d % 3600) % 60); // Seconds
  
    // Format hours, minutes, and seconds
    const hDisplay = h > 0 ? (h <= 9 ? "0" : "") + h : "00";
    const mDisplay = m > 0 ? (m <= 9 ? "0" : "") + m : "00";
    const sDisplay = s > 0 ? (s <= 9 ? "0" : "") + s : "00";
  
    // Return the time string in HH:MM:SS format
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };
  
export const formatNumber = (num: number | undefined): string => {
    if (num !== undefined && num !== null) {
      return `${num.toFixed(2)}`;
    }
    
    return `0`;
  };
  