import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface IGrade {
  gid: number;
  grade: string;
}

interface IMaterial {
  material: string;
  matid: number;
  gradeList: IGrade[];
}

interface IMaterialDropDownProps {
  materialData: IMaterial[];
  onMaterialSelect: (matid: number, gid: number) => void;
  selectedMatType: number;
  selectedMatGrade: number;
}

const MaterialDropDown: React.FC<IMaterialDropDownProps> = ({
  materialData,
  onMaterialSelect,
  selectedMatType,
  selectedMatGrade,
}) => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGid = parseInt(event.target.value, 10);
    const selectedMatid = parseInt(
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-matid"
      ) || "0",
      10
    );
    onMaterialSelect(selectedMatid, selectedGid);
  };

  return (
    <Form.Group className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
        Material Type
      </Form.Label>
      <Form.Select
        value={selectedMatGrade}
        onChange={handleSelect}
        aria-label="Material Grade Select"
      >
        <option value={0}>Select Material Type</option>
        {materialData.map((material) => (
          <optgroup label={material.material} key={material.matid}>
            {material.gradeList.map((grade) => (
              <option
                key={grade.gid}
                value={grade.gid}
                data-matid={material.matid}
              >
                {grade.grade}
              </option>
            ))}
          </optgroup>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default MaterialDropDown;
