import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface IMachineDropDownProps {
  machines: { machine: string }[];
  onMachineSelect: (machine: string) => void;
  selectedMachine: string;
}

const MachineDropDown: React.FC<IMachineDropDownProps> = ({
  machines,
  onMachineSelect,
  selectedMachine,
}) => {

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const machine = event.target.value;
    if (machine === selectedMachine) {
      onMachineSelect(""); // Clear selection if the same machine is selected again
    } else {
      onMachineSelect(machine);
    }
  };

  return (
    // <div className="machinedrop">
    <Form.Group controlId="machineSelect" className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
       Machine Manufacturer
      </Form.Label>
      <Form.Select
        value={selectedMachine || ""}
        onChange={handleSelect}
        aria-label="Select Machine Manufacturer"
      >
        <option value="">Start Here</option>
        {machines.map((item, index) => (
          <option key={index} value={item.machine}>
            {item.machine}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
    // </div>
  );
};

export default MachineDropDown;
