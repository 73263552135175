import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";

const Quantity6: React.FC = () => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  return (
    <div className="cart-card h-100 d-flex flex-column">
      <div className="cart-card-body6 card-inner-padding flex-grow-1">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="best-for-title mb-0">PRICE PER BLADE</p>
          <span className="price-text fw-bold">$30.84</span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="label-text fw-bold">QUANTITY</span>
          <div className="d-flex align-items-center">
            <Button
              variant="light"
              className="btn-wave fs-18 equal-width-btn btn-grey-color shadow-sm"
              onClick={decreaseQuantity}
            >
              <FaMinus />
            </Button>
            <div className="quantity-display-box text-center mx-3 border border-secondary rounded p-2">
              <span className="quantity-number">{quantity}</span>
            </div>
            <Button
              variant="light"
              className="btn-wave fs-18 equal-width-btn btn-grey-color shadow-sm"
              onClick={increaseQuantity}
            >
              <FaPlus />
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="label-text fw-bold">TOTAL</span>
          <span className="total-text fw-bold">$29.84*</span>
        </div>
        <div className="mb-1">
          <p className="free-shipping-text text-center fw-medium">
            {quantity < 5
              ? "Order 5 blades and receive FREE Shipping"
              : "Your Order Qualifies for Shipping FREE"}
          </p>
        </div>
      </div>
      {/* Add padding on the left and right to the button container */}
      <div className="d-flex justify-content-center mt-auto px-3 mb-1">
        <Button
          variant="danger"
          className="btn-add-to-cart shadow-sm px-4 w-100"
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
};

export default Quantity6;
