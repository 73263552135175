import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import MachineDropDown from "./MachineDropDown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import {
  calculateSpeedFeed,
  fetchBladeWidthList,
  fetchMachineList,
  fetchMaterialGradeList,
  fetchModel,
  setMaterialGradeListEmpty,
  setModelListEmpty,
} from "../redux/features/speedFeedManagerSlice";
import ModelDropDown from "./ModelDropDown";
import MaterialDropDown from "./MaterialDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";
import MaterialShapeSelector from "./MaterialShapeSelector";
import MaterialDimensions from "./MaterialDimensions";
import BundleSelection from "./BundleSelection";
import { useNavigate } from "react-router-dom";
import ToastDisplay from "./ToastDisplay";
import BladeWidthDropDown from "./BladeWidthDropDown";

interface ISpeedFeedData {
  machinemake: string;
  machinemodel: number;
  matshape: number;
  shape: string;
  width: number;
  diameter: number;
  innerdia: number;
  wall: number;
  height: number;
  numpieces: string;
  bundletype: number;
  across: number;
  tall: number;
  mattype: number;
  matgrade: number;
  heattreated: number | null;
  matHardness: number;
  bladetype: number;
  bladewidth: number;
  totalcuts: number;
  shopid: number;
  pieces: number;
  forHMS: boolean;
  generatePDF: boolean;
  emailTo: string[];
}

const defaultSpeedFeedData: ISpeedFeedData = {
  machinemake: "",
  machinemodel: 0,
  matshape: 0,
  shape: "",
  width: 0,
  diameter: 0,
  innerdia: 0,
  wall: 0,
  height: 0,
  numpieces: "",
  bundletype: 0,
  across: 0,
  tall: 0,
  mattype: 0,
  matgrade: 0,
  heattreated: null,
  matHardness: 0,
  bladetype: 0,
  bladewidth: 0,
  totalcuts: 0,
  shopid: 0,
  pieces: 0,
  forHMS: false,
  generatePDF: false,
  emailTo: [],
};

const SpeedAndFeed = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    machineListData,
    modelListData,
    materialGradeData,
    bladeWidthData,
    calculateLoading,
  } = useSelector((state: RootState) => state.speedFeedManager);

  const [speedFeedData, setSpeedFeedData] = useState(defaultSpeedFeedData);
  const [isMaterialLoaded, setIsMaterialLoaded] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastVariant, setToastVariant] = useState<"success" | "error">(
    "success"
  );
  const [showToast, setShowToast] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchMachineList());
  }, [dispatch]);

  useEffect(() => {
    if (speedFeedData.machinemake) {
      dispatch(fetchModel(speedFeedData.machinemake));
    } else {
      dispatch(setModelListEmpty());
      dispatch(setMaterialGradeListEmpty());
    }
  }, [speedFeedData.machinemake, dispatch]);

  useEffect(() => {
    if (speedFeedData.machinemodel !== 0 && !isMaterialLoaded) {
      dispatch(fetchMaterialGradeList());
      setIsMaterialLoaded(true);
    }
  }, [speedFeedData.machinemodel, dispatch, isMaterialLoaded]);

  useEffect(() => {
    if (speedFeedData.machinemodel && speedFeedData.mattype) {
      dispatch(
        fetchBladeWidthList({
          machineModel: speedFeedData.machinemodel,
          matid: speedFeedData.mattype,
        })
      );
    }
  }, [speedFeedData.machinemodel, speedFeedData.mattype, dispatch]);

  const handleMachineSelect = (machine: string) => {
    setSpeedFeedData({
      ...speedFeedData,
      machinemake: machine,
      machinemodel: 0,
      mattype: 0,
      matgrade: 0,
      matshape: 0,
      shape: "",
      heattreated: null,
      matHardness: 0,
      numpieces: "",
      totalcuts: 0,
      bladewidth: 0,
      wall: 0,
      height: 0,
      width: 0,
      diameter: 0,
      innerdia: 0,
      across: 0,
      tall: 0,
    });
    setIsMaterialLoaded(false);
  };

  const handleModelSelect = (sid: number) => {
    setSpeedFeedData({ ...speedFeedData, machinemodel: sid, bladewidth: 0 });
  };

  const handleMaterialSelect = (matid: number, gid: number) => {
    setSpeedFeedData((prevData) => ({
      ...prevData,
      mattype: matid,
      matgrade: gid,
    }));
  };

  const handleStockTypeSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const stockType = parseInt(event.target.value);
    setSpeedFeedData({
      ...speedFeedData,
      matshape: stockType,
      heattreated: null,
      matHardness: 0,
      numpieces: "",
      totalcuts: 0,
      bladewidth: 0,
      wall: 0,
      height: 0,
      width: 0,
      diameter: 0,
      innerdia: 0,
      across: 0,
      tall: 0,
      shape: "",
    });
  };

  const handleShapeSelect = (shapeId: string) => {
    setSpeedFeedData({
      ...speedFeedData,
      shape: shapeId,
      diameter: 0,
      wall: 0,
      height: 0,
      width: 0,
      innerdia: 0,
      heattreated: null,
      matHardness: 0,
      numpieces: "",
      across: 0,
      tall: 0,
      totalcuts: 0,
      bladewidth: 0,
    });
  };

  const handleDimensionChange = (updatedDimensions: any) => {
    const key = Object.keys(updatedDimensions)[0];
    const { diameter, wall, height, width, innerdia } = updatedDimensions[key];

    setSpeedFeedData({
      ...speedFeedData,
      diameter: diameter !== undefined ? diameter : speedFeedData.diameter,
      wall: wall !== undefined ? wall : speedFeedData.wall,
      height: height !== undefined ? height : speedFeedData.height,
      width: width !== undefined ? width : speedFeedData.width,
      innerdia: innerdia !== undefined ? innerdia : speedFeedData.innerdia,
    });
  };

  const handleHeatTreatedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value);
    setSpeedFeedData({
      ...speedFeedData,
      heattreated: value,
      numpieces: "",
      across: 0,
      tall: 0,
      totalcuts: 0,
      bladewidth: 0,
    });
  };

  const handleMatHardnessChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(event.target.value);
    setSpeedFeedData({ ...speedFeedData, matHardness: value });
  };

  const handleNumPiecesChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setSpeedFeedData({
      ...speedFeedData,
      numpieces: value,
      totalcuts: 0,
      bladewidth: 0,
    });
  };

  const handleAcrossChange = (value: number | null) => {
    setSpeedFeedData({
      ...speedFeedData,
      bladewidth: 0,
      totalcuts: 0,
      across: value !== null ? value : 0,
    });
  };

  const handleTallChange = (value: number | null) => {
    setSpeedFeedData({
      ...speedFeedData,
      bladewidth: 0,
      totalcuts: 0,
      tall: value !== null ? value : 0,
    });
  };

  const handleTotalCutsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 0) {
      newValue = 0;
    }
    setSpeedFeedData({ ...speedFeedData, totalcuts: newValue });
  };

  const handleBladeWidthSelect = (data: number) => {
    setSpeedFeedData({ ...speedFeedData, bladewidth: data });
  };

  const areAllDimensionsFilled = () => {
    const { diameter, wall, height, width, innerdia, shape } = speedFeedData;

    switch (shape) {
      case "solid-sq":
        return width > 0;
      case "solid-bar":
        return diameter > 0;
      case "solid-rect-tall":
      case "solid-rect-wide":
        return height > 0 && width > 0;
      case "tube-sq":
        return wall > 0 && width > 0;
      case "tube-bar":
        return diameter > 0 && (innerdia > 0 || wall > 0);
      case "tube-rect-tall":
      case "tube-rect-wide":
      case "struct-angle":
        return wall > 0 && height > 0 && width > 0;
      default:
        return (
          diameter > 0 || wall > 0 || height > 0 || width > 0 || innerdia > 0
        );
    }
  };

  const handleSpeedFeedCalculate = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(calculateSpeedFeed(speedFeedData))
      .unwrap()
      .then(() => {
        navigate("/sawbladedata/?pm=1", { state: { speedFeedData } });
      })
      .catch(() => {
        setToastMessage("Invalid Request, Select different values");
        setToastVariant("error");
        setShowToast(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  return (
    <div className="shadow p-1 mb-2 text-dark rounded">
      <Form onSubmit={handleSpeedFeedCalculate}>
        <Row className="mt-2">
          <Col xl={6} lg={6} md={12} sm={12}>
            <MachineDropDown
              machines={machineListData}
              selectedMachine={speedFeedData.machinemake}
              onMachineSelect={handleMachineSelect}
            />
          </Col>
          {speedFeedData.machinemake && modelListData.length > 0 && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <ModelDropDown
                models={modelListData}
                onModelSelect={handleModelSelect}
                selectedModelSid={speedFeedData.machinemodel}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {speedFeedData.machinemodel > 0 && materialGradeData.length > 0 && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <MaterialDropDown
                materialData={materialGradeData}
                onMaterialSelect={handleMaterialSelect}
                selectedMatType={speedFeedData.mattype}
                selectedMatGrade={speedFeedData.matgrade}
              />
            </Col>
          )}
          {speedFeedData.matgrade > 0 && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <Form.Group controlId="stockTypeSelect" className="machineDrop">
                <Form.Label className="machineLabel fw-normal fs-6">
                  <span className="icon-drop">
                    <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
                  </span>
                  Material Stock
                </Form.Label>
                <Form.Select
                  value={speedFeedData.matshape}
                  onChange={handleStockTypeSelect}
                >
                  <option value={0}>Select Stock</option>
                  <option value={1}>Solid</option>
                  <option value={2}>Tubular</option>
                  <option value={3}>Structural</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {speedFeedData.matshape > 0 && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <MaterialShapeSelector
                matshape={speedFeedData.matshape}
                onShapeSelect={handleShapeSelect}
              />
            </Col>
          )}
          {speedFeedData.shape !== "" && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <MaterialDimensions
                stock={speedFeedData.matshape}
                shape={speedFeedData.shape}
                onDimensionChange={handleDimensionChange}
                dimensions={{
                  diameter: speedFeedData.diameter,
                  wall: speedFeedData.wall,
                  height: speedFeedData.height,
                  width: speedFeedData.width,
                  innerdia: speedFeedData.innerdia,
                }}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {areAllDimensionsFilled() && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <Form.Group controlId="heatTreated" className="machineDrop">
                <Form.Label className="machineLabel fw-normal fs-6">
                  <span className="icon-drop">
                    <FontAwesomeIcon className="fs-3" icon={fas.faHeat} />
                  </span>
                  Heat Treated (RC)<span>*</span>
                </Form.Label>
                <div className="p-1">
                  <Form.Check
                    inline
                    type="radio"
                    id="heatTreatedYes"
                    name="heattreated"
                    value="1"
                    label="Yes"
                    checked={speedFeedData.heattreated === 1}
                    onChange={handleHeatTreatedChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="heatTreatedNo"
                    name="heattreated"
                    value="0"
                    label="No"
                    checked={speedFeedData.heattreated === 0}
                    onChange={handleHeatTreatedChange}
                  />
                </div>
              </Form.Group>
            </Col>
          )}
          {areAllDimensionsFilled() && speedFeedData.heattreated === 1 && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <Form.Group controlId="matHardness" className="machineDrop">
                <Form.Label className="machineLabel fw-normal fs-6">
                  <span className="icon-drop">
                    <FontAwesomeIcon className="fs-3" icon={fas.faHeat} />
                  </span>
                  Hardness<span>*</span>
                </Form.Label>
                <Form.Select
                  value={
                    speedFeedData.matHardness !== null
                      ? speedFeedData.matHardness
                      : "0"
                  }
                  onChange={handleMatHardnessChange}
                >
                  {[...Array(101).keys()].map((val) => {
                    const formattedValue =
                      val < 10 ? `0${val}` : val.toString();
                    return (
                      <option key={val} value={val}>
                        {formattedValue}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          {areAllDimensionsFilled() && speedFeedData.heattreated === 0 && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="border border-3 text-muted fw-bold d-flex justify-content-center align-items-center h-75">
                No Hardness Specification Selected
              </div>
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {areAllDimensionsFilled() && speedFeedData.heattreated !== null && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <Form.Group controlId="numpieces">
                <Form.Label className="machineLabel fw-normal fs-6">
                  <span className="icon-drop">
                    <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
                  </span>
                  Cutting a Bundle ?
                </Form.Label>
                <Form.Select
                  value={speedFeedData.numpieces || ""}
                  onChange={handleNumPiecesChange}
                  className="mb-0"
                >
                  <option value={""}>Select</option>
                  <option value={"bundle"}>Yes</option>
                  <option value={"single"}>No</option>
                </Form.Select>
                <span className="text-danger form-text">
                  - are we cutting multiple pieces at one time?
                </span>
              </Form.Group>
            </Col>
          )}
          {areAllDimensionsFilled() && speedFeedData.numpieces === "single" && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className=" border border-3 text-muted fw-bold d-flex justify-content-center align-items-center h-100">
                Not Cutting a bundle
              </div>
            </Col>
          )}
          {areAllDimensionsFilled() && speedFeedData.numpieces === "bundle" && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <BundleSelection
                stock={speedFeedData.matshape}
                shape={speedFeedData.shape}
                onAcrossChange={handleAcrossChange}
                onTallChange={handleTallChange}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {areAllDimensionsFilled() &&
            speedFeedData.numpieces !== "" &&
            (speedFeedData.numpieces !== "bundle" ||
              (speedFeedData.tall > 0 && speedFeedData.across > 0)) && (
              <Col sm={12} md={12} lg={6} xl={6}>
                <Form.Group className="machineDrop">
                  <Form.Label className="machineLabel fw-normal fs-6 mb-0 bg-dark-gradient">
                    <span className="icon-drop">
                      <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
                    </span>
                    How Many Total Cuts?
                  </Form.Label>
                  <span className="form-text text-danger">
                    - how many cuts for the whole job?
                  </span>
                  <Form.Control
                    type="number"
                    id="totalcuts"
                    name="totalcuts"
                    placeholder="Number of cuts > 0"
                    value={speedFeedData.totalcuts || ""}
                    onChange={handleTotalCutsChange}
                    min={0}
                  />
                </Form.Group>
              </Col>
            )}
          {areAllDimensionsFilled() &&
            speedFeedData.numpieces !== "" &&
            speedFeedData.totalcuts > 0 && (
              <Col xl={6} lg={6} md={12} sm={12}>
                <BladeWidthDropDown
                  bladeWidthData={bladeWidthData}
                  onBladeWidthSelect={handleBladeWidthSelect}
                  selectedBladeWidth={speedFeedData.bladewidth}
                />
                {bladeWidthData.length === 0 && (
                  <span className="text-danger form-text">
                    - No bladewidth found
                  </span>
                )}
              </Col>
            )}
        </Row>
        <Row className="mt-2">
          {areAllDimensionsFilled() && speedFeedData.bladewidth > 0 && (
            <Col sm={12} md={12} lg={6} xl={6}>
              <Button
                type="submit"
                style={{ backgroundColor: "rgb(6, 26, 43)" }}
                disabled={calculateLoading}
              >
                {calculateLoading && <Spinner animation="border" size="sm" />}
                Calculate
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      <ToastDisplay
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        variant={toastVariant}
      />
    </div>
  );
};

export default SpeedAndFeed;
