import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; 
import ApplicationImg from '../assets/images/application-title-img.png';
import { Image, Row, Col } from 'react-bootstrap';

const BestForList: React.FC = () => {
  return (
    <div className="cart-card">
      <div className="cart-card-body6 card-inner-padding">
        <div>
          <p className="best-for-title">BEST FOR</p>
          <Image src={ApplicationImg} width={180} height={30} fluid className="mb-3" />
          <ul className="best-for-list list-unstyled">
            <Row>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Tool Steels</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Alloy Steels</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Titanium Alloys</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Bearing Steels</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Structural Steels</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Stainless Steels</li></Col>
              <Col xs={6} md={12}><li><FaCheckCircle className="icon" /> Mold Steels</li></Col>
            </Row>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BestForList;
