import React, { useState } from 'react';
import { Navbar, Form, Nav, Container, Modal, Accordion, NavDropdown } from 'react-bootstrap';
import { FaUser, FaShoppingCart, FaBars, FaSearch } from 'react-icons/fa';
import Logo from "../assets/images/sawbladcom-logo.png";

const Header: React.FC = () => {
  const [showNavPopup, setShowNavPopup] = useState(false);

  const handleOpenNavPopup = () => setShowNavPopup(true);
  const handleCloseNavPopup = () => setShowNavPopup(false);

  return (
    <div className='sticky-header'>
      <Navbar className="custom-navbar2" expand="lg">
        <div className="logo-container">
          <Navbar.Brand href="#" className="custom-navbar-brand">
            <img src={Logo} alt="Logo" className="custom-logo" />
          </Navbar.Brand>
        </div>
        <div className="d-lg-none d-flex align-items-center mobile-icons">
          <Nav.Link href="#" className="text-light muser-icon">
            <FaUser />
          </Nav.Link>
          <Nav.Link href="#" className="text-light mucart-icon">
            <FaShoppingCart />
          </Nav.Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="border-0 mbar-icon"
            onClick={handleOpenNavPopup}
          >
            <FaBars />
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="d-lg-flex justify-content-between nav-right-items">
          <Form className="d-flex custom-search-form ms-auto d-none d-lg-flex" role="search">
            <div className="custom-search-input-wrapper">
              <input
                className="form-control custom-search-input"
                type="search"
                placeholder="Product Search - (Name/SKU)"
                aria-label="Search"
              />
              <FaSearch className="custom-search-icon" />
            </div>
          </Form>
          <Nav className="ms-auto d-none d-lg-flex align-items-center">
            <Nav.Link href="#" className="d-flex align-items-center text-light custom-nav-link">
              <FaUser className="me-2" /> LOGIN
            </Nav.Link>
            <Nav.Link href="#" className="d-flex align-items-center text-light custom-nav-link">
              <FaShoppingCart className="me-2" /> CART
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Bottom navbar for desktop view */}
      <Navbar expand="lg" className="custom-navbar bg-light justify-content-center d-none d-lg-flex">
        <Container className="d-flex justify-content-between">
          <Navbar.Collapse id="navbar-nav">
            <Nav className="mx-auto">
              <NavDropdown title="BAND SAW BLADES" id="bandsawblades-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="metal-cutting" href="#">Metal Cutting</NavDropdown.Item>
                <NavDropdown.Item key="wood-cutting" href="#">Wood Cutting</NavDropdown.Item>
                <NavDropdown.Item key="others" href="#">Others</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="BAND SAWS" id="bandsaws-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="horizontal" href="#">Horizontal</NavDropdown.Item>
                <NavDropdown.Item key="vertical" href="#">Vertical</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="PARTS & UPGRADES" id="partsupgrades-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="blades" href="#">Blades</NavDropdown.Item>
                <NavDropdown.Item key="parts" href="#">Parts</NavDropdown.Item>
                <NavDropdown.Item key="accessories" href="#">Accessories</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="SAWING RESOURCES" id="sawingresources-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="guides" href="#">Guides</NavDropdown.Item>
                <NavDropdown.Item key="tips" href="#">Tips</NavDropdown.Item>
                <NavDropdown.Item key="videos" href="#">Videos</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Nav className="align-items-center contact-us-container">
          <Nav.Link className="contact-us active" href="#">CONTACT US</Nav.Link>
        </Nav>
      </Navbar>

      {/* Full-screen Navigation Popup for mobile view */}
      <Modal
        show={showNavPopup}
        onHide={handleCloseNavPopup}
        className="full-screen-modal"
        centered
      >
        <Modal.Body className="full-screen-modal-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5>Navigation</h5>
            <button onClick={handleCloseNavPopup} className="btn-close"></button>
          </div>
          <Accordion className='accordionm'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>BAND SAW BLADES</Accordion.Header>
              <Accordion.Body>
                <Nav.Link href="#">Metal Cutting</Nav.Link>
                <Nav.Link href="#">Wood Cutting</Nav.Link>
                <Nav.Link href="#">Others</Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>BAND SAWS</Accordion.Header>
              <Accordion.Body>
                <Nav.Link href="#">Horizontal</Nav.Link>
                <Nav.Link href="#">Vertical</Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>PARTS & UPGRADES</Accordion.Header>
              <Accordion.Body>
                <Nav.Link href="#">Blades</Nav.Link>
                <Nav.Link href="#">Parts</Nav.Link>
                <Nav.Link href="#">Accessories</Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>SAWING RESOURCES</Accordion.Header>
              <Accordion.Body>
                <Nav.Link href="#">Guides</Nav.Link>
                <Nav.Link href="#">Tips</Nav.Link>
                <Nav.Link href="#">Videos</Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Nav.Link href="#" className="mt-3">CONTACT US</Nav.Link>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
