import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";

interface IMaterialDimensionsProps {
  stock: number;
  shape: string;
  onDimensionChange: (updatedDimensions: any) => void;
  dimensions: {
    diameter: number;
    wall: number;
    height: number;
    width: number;
    innerdia: number;
  };
}

const MaterialDimensions: React.FC<IMaterialDimensionsProps> = ({
  stock,
  shape,
  onDimensionChange,
  dimensions,
}) => {
  const uniqueKey = `${stock}-${shape}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = parseFloat(value);

    if (newValue < 0) {
      newValue = 0;
    }

    onDimensionChange({
      [uniqueKey]: {
        ...dimensions,
        [name]:
          !isNaN(newValue) && newValue !== 0
            ? parseFloat(newValue.toFixed(2))
            : "",
      },
    });
  };

  const renderDimensions = () => {
    switch (`${stock}-${shape}`) {
      case "1-solid-sq":
        return (
          <>
            <Col xs={3}>
              <img
                src={require("../assets/images/dim-square-solid.png")}
                alt="Square Solid"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  id={`width-${uniqueKey}`}
                  name="width"
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                  min={0}
                />
              </Form.Group>
            </Col>
          </>
        );
      case "1-solid-bar":
        return (
          <>
            <Col xs={3}>
              <img
                src={require("../assets/images/dim-round-solid.png")}
                alt="Round Solid"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Diameter (in.): </Form.Label>
                <Form.Control
                  type="number"
                  id={`diameter-${uniqueKey}`}
                  name="diameter"
                  placeholder="Diameter"
                  value={dimensions.diameter || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "1-solid-rect-tall":
        return (
          <>
            <Col xs={4}>
              <img
                src={require("../assets/images/dim-rect-tall-solid.png")}
                alt="Rectangle Solid"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Height (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  placeholder="Height"
                  id={`height-${uniqueKey}`}
                  value={dimensions.height || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "1-solid-rect-wide":
        return (
          <>
            <Col xs={4}>
              <img
                src={require("../assets/images/dim-rect-wide-solid.png")}
                alt="Rectangle Wide Solid"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Height (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  id={`height-${uniqueKey}`}
                  placeholder="Height"
                  value={dimensions.height || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "2-tube-sq":
        return (
          <>
            <Col xs={3}>
              <img
                src={require("../assets/images/dim-square-tube.png")}
                alt="Square Tabular"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Wall (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="wall"
                  id={`wall-${uniqueKey}`}
                  placeholder="Wall Thickness"
                  value={dimensions.wall || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "2-tube-bar":
        return (
          <>
            <Col xs={3}>
              <img
                src={require("../assets/images/dim-round-tube.png")}
                alt="Tube Round"
                width="148"
                height="175"
              />
            </Col>
            <Col xs={8} className="ps-4">
              <Form.Group>
                <Form.Label className="mb-0">OD (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="diameter"
                  id={`diameter-${uniqueKey}`}
                  placeholder="Outer Dia."
                  value={dimensions.diameter || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Row className="mt-2">
                <Col xs={5}>
                  <Form.Group>
                    <Form.Label className="mb-0">ID (in.): </Form.Label>
                    <Form.Control
                      type="number"
                      name="innerdia"
                      id={`innerdia-${uniqueKey}`}
                      placeholder="Inner Dia."
                      value={dimensions.innerdia || ""}
                      onChange={handleChange}
                      className="mt-0"
                      disabled={!!dimensions.wall}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2} className="text-center px-2">
                  <strong className="text-danger">OR</strong>
                </Col>
                <Col xs={5}>
                  <Form.Group>
                    <Form.Label className="mb-0">Wall (in.): </Form.Label>
                    <Form.Control
                      type="number"
                      name="wall"
                      id={`wall-${uniqueKey}`}
                      placeholder="Wall"
                      value={dimensions.wall || ""}
                      onChange={handleChange}
                      className="mt-0"
                      disabled={!!dimensions.innerdia}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </>
        );

      case "2-tube-rect-tall":
        return (
          <>
            <Col xs={4}>
              <img
                src={require("../assets/images/dim-rect-tall-tube.png")}
                alt="Rectangle Tabular"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Wall (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="wall"
                  id={`wall-${uniqueKey}`}
                  placeholder="Wall Thickness"
                  value={dimensions.wall || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Height (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  id={`height-${uniqueKey}`}
                  placeholder="Height"
                  value={dimensions.height || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "2-tube-rect-wide":
        return (
          <>
            <Col xs={4}>
              <img
                src={require("../assets/images/dim-rect-wide-tube.png")}
                alt="Rectangle Wide Tabular"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Wall (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="wall"
                  id={`wall-${uniqueKey}`}
                  placeholder="Wall Thickness"
                  value={dimensions.wall || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Height (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  id={`height-${uniqueKey}`}
                  placeholder="Height"
                  value={dimensions.height || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Width (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="Width"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      case "3-struct-angle":
        return (
          <>
            <Col xs={4}>
              <img
                src={require("../assets/images/dim-struct-angle.png")}
                alt="Structural Angle"
                width="148"
                height="129"
              />
            </Col>
            <Col xs={8} className="px-5">
              <Form.Group>
                <Form.Label className="mb-0">Thickness (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="wall"
                  id={`wall-${uniqueKey}`}
                  placeholder="Thickness"
                  value={dimensions.wall || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">A Leg (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  id={`width-${uniqueKey}`}
                  placeholder="A Leg"
                  value={dimensions.width || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">B Leg (in.): </Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  id={`height -${uniqueKey}`}
                  placeholder="B Leg"
                  value={dimensions.height || ""}
                  onChange={handleChange}
                  className="mt-0"
                />
              </Form.Group>
            </Col>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Form.Group controlId="dimensionSelect" className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
        Material Dimensions
      </Form.Label>
      <Row className="form-group" style={{ minHeight: "160px" }}>
        {renderDimensions()}
      </Row>
      <span className="text-danger form-text px-3">
        - Provide dimensions for cutting orientation using Decimals (ex: 2.5,
        not 2 1/2)
      </span>
    </Form.Group>
  );
};

export default MaterialDimensions;
